import React from 'react'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { css } from '../../../styled-system/css'
import { HeadFC } from 'gatsby'
import SEO from '../../components/SEO'

const Privacy = () => {
  return (
    <HeaderAndFooterLayout>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '4rem',
        })}
      >
        <div
          className={css({
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '4rem',
          })}
        >
          <div>
            <h1
              className={css({
                alignItems: 'center',
                textAlign: 'center',
                fontFamily: 'Exo2',
                fontSize: '5rem',
                '@media (max-width: 768px)': {
                  fontSize: '3rem',
                },
              })}
            >
              Votre vie privée et le RGPD
            </h1>
          </div>
          <div
            className={css({
              marginTop: '2rem',
            })}
          >
            <p
              className={css({
                fontSize: '1.2rem',
                color: '#6e6e73',
              })}
            >
              <span>
                Soucieux de la protection de votre vie privée, Digit Universe
                s’engage à assurer le meilleur niveau de protection de vos
                données personnelles conformément à la loi Informatique et
                Libertés du 6 janvier 1978 modifiée et au Règlement (UE)
                2016/679 du Parlement européen et du Conseil du 27 avril 2016.
              </span>
            </p>
          </div>
          <div
            className={css({
              marginTop: '2rem',
            })}
          >
            <p
              className={css({
                fontSize: '1.2rem',
                color: '#6e6e73',
              })}
            >
              <span>
                La présente politique de confidentialité définit et vous informe
                de la manière dont le site digituniverse.fr utilise et protège
                les informations que vous nous fournissez, lorsque vous utilisez
                notre site internet. Cette politique de confidentialité est
                susceptible d’être modifiée ou complétée en vue de se conformer
                à toute évolution législative, règlementaire, jurisprudentielle
                ou technologique.
              </span>
            </p>
          </div>
          <div
            className={css({
              marginTop: '2rem',
            })}
          >
            <h2>COLLECTE DES RENSEIGNEMENTS PERSONNELS</h2>
          </div>
          <div
            className={css({
              marginTop: '2rem',
            })}
          >
            <p
              className={css({
                fontSize: '1.2rem',
                color: '#6e6e73',
              })}
            >
              <span>
                D’une manière générale, il vous est possible de visiter le site
                sans communiquer aucune information personnelle vous concernant.
                Pour bénéficier de l’intégralité de nos services (infolettre,
                téléchargement de produits, commentaires, formulaires, sondage),
                nous vous demandons certaines informations nécessaires à
                l’utilisation désirée.
              </span>
            </p>
          </div>
          <div
            className={css({
              marginTop: '2rem',
            })}
          >
            <p
              className={css({
                fontSize: '1.2rem',
                color: '#6e6e73',
              })}
            >
              <span>
                En fonction de vos besoins et pour l’amélioration de notre
                service, nous pourrions collecter certaines des données
                suivantes:
              </span>
            </p>
          </div>
          <div
            className={css({
              marginTop: '2rem',
            })}
          >
            <p
              className={css({
                fontSize: '1.2rem',
                color: '#6e6e73',
              })}
            >
              <span>
                -Adresse électronique (e-mail) <br />
                -Nom et prénom <br />
                -Numéro de téléphone <br />
                -Adresse, État, Province, Code postal, Ville <br />
                -Numéro de carte de crédit (lors d’une transaction)
              </span>
            </p>
          </div>
          <div
            className={css({
              marginTop: '2rem',
            })}
          >
            <p
              className={css({
                fontSize: '1.2rem',
                fontWeight: 'bold',
                color: '#6e6e73',
              })}
            >
              <span>POUR NOUS CONTACTER</span>
            </p>
          </div>
          <div
            className={css({
              marginTop: '2rem',
            })}
          >
            <p
              className={css({
                fontSize: '1.2rem',
                color: '#6e6e73',
              })}
            >
              <span>
                POUR NOUS CONTACTER Via courriel :{' '}
                <a href="mailto:contact@digituniverse.com">
                  contact@digituniverse.com
                </a>{' '}
                <br />
                Via téléphone : <a href="tel:0678258593">06 78 25 85 93</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </HeaderAndFooterLayout>
  )
}

export default Privacy

export const Head: HeadFC = () =>  <SEO 
title="RGPD - Digit Universe" 
description="Les RGPD de Digit Universe" 
robotsContent="noindex, nofollow"
/>